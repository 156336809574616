@import './shared/import-once';
@import './shared/colors';

@include exports('inputs') {
  .input {
    padding: 0.4em;
    border-radius: 4px;
    height: 1.1em;
    box-sizing: initial;

    color: $color-font-black;
    background-color: $color-font-white;

    font-weight: bold;
    font-size: 1em;

    cursor: text;

    &:focus {
      background-color: lighten($color-font-white, 5);
    }
  }

  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 0.4em;
    border-radius: 4px;

    color: $color-font-white;
    background-color: $color-primary-dark;

    font-weight: bold;
    font-size: 1em;

    &:hover {
      background-color: darken($color-primary-dark, 5);
    }

    &:disabled {
      cursor: pointer;
      background-color: transparentize($color-primary-dark, 0.1);
    }
  }
}
