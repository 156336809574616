@font-face {
  font-family: 'OpenSans';
  font-weight: 300;
  src: url('../fonts/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: normal;
  src: url('../fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  src: url('../fonts/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: bold;
  src: url('../fonts/OpenSans-Bold.ttf');
}
