@import '../../styles/shared/colors';
@import '../../styles/shared/media';

.content.main-page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: $color-primary;

  padding: 1em;

  .files-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  .file-input {
    width: 10em;
    height: 10em;

    margin: 0.5em;
    border-radius: 3px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &:focus {
      outline: none;
    }

    .drop-zone {
      width: 100%;
      height: 100%;

      line-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      border-radius: 3px;

      border: 0.5em dashed $color-secondary;

      svg {
        fill: $color-secondary;
      }

      &.active {
        $color-accent: $color-primary-dark;
        border: 0.5em dashed $color-accent;
        svg {
          fill: $color-accent;
        }
      }

      &:hover {
        $color-accent: darken($color-secondary, 5);
        border: 0.5em dashed $color-accent;
        svg {
          fill: $color-accent;
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  .file-button {
    width: 10em;
    height: 10em;

    padding: 0.5em;
    margin: 0.5em;
    border-radius: 3px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: $color-font-black;
    background-color: $color-secondary;

    font-weight: 600;
    text-decoration: none;
    text-align: center;

    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.2em darken($color-primary, 10);
    }

    &:hover {
      $color-accent: darken($color-secondary, 10);
      background-color: $color-accent;
    }

    svg {
      fill: $color-font-black;
      margin-bottom: 0.5em;
    }

    .label {
      &--name {
        width: 100%;
        margin-bottom: 0.5em;
        overflow-wrap: break-word;
      }

      &--size {
        font-size: 0.8em;
        color: transparentize($color-font-black, 0.5);
      }
    }
  }

  .separator {
    height: 1em;
    line-height: 1em;
    width: 1em;
    font-weight: bold;
    color: $color-secondary;
    margin: 1em;

    position: relative;

    &::before,
    &::after {
      display: block;
      position: absolute;
      right: calc(100% + 1em);
      top: 50%;

      content: ' ';
      background-color: $color-secondary;
      width: 7em;
      height: 0.2em;
      border-radius: 3px;
    }

    &::after {
      left: calc(100% + 1em);
    }
  }

  .peer-form {
    display: flex;
    flex-direction: column;
    width: 15em;
    padding: 0.5em;

    .input {
      margin-bottom: 0.5em;
    }

    button {
      width: 100%;
    }
  }

  @include media-down(sm) {
    hr {
      width: 100%;
    }

    .peer-form {
      width: 100%;
    }

    .file-input,
    .file-button {
      width: 100%;
      height: 5em;
    }

    .file-button svg {
      display: none;
    }
  }
}
